import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ColumnMode, NgxDatatableModule } from '@swimlane/ngx-datatable';
import _ from 'lodash';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { ApiService } from 'src/services/api.service';

import { SlackBlocksModule } from '../slack-blocks/slack-blocks.module';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    NgxDatatableModule,
    SlackBlocksModule,
    NgxTippyModule,
    SurveyAnalyticsPage,
  ],
  selector: 'app-survey-analytics',
  templateUrl: './survey-analytics.page.html',
  styleUrls: ['./survey-analytics.page.scss'],
})
export class SurveyAnalyticsPage implements OnInit {
  @Input() surveyId: string;
  @Input() outboundId?: string;

  survey: any;
  isLoading = true;
  data: any[] = [];
  ColumnMode = ColumnMode;
  hasSurvey: boolean;

  surveyScore = 0;
  totalResponses = 0;
  surveyType?: string;
  surveyResponses: any[] = [];
  surveyScoresByAssignee: any[] = [];

  constructor(private apiService: ApiService) {}

  async ngOnInit() {
    this.getSurveyData();
  }

  async getSurveyData() {
    this.isLoading = true;

    const [surveyResponse, surveyResponsesResponse]: [any, any] = await Promise.all([
      this.apiService.getPromise(
        `/surveys/${this.surveyId}/analytics${this.outboundId ? `?outboundId=${this.outboundId}` : ''}`,
      ),
      this.apiService.postPromise(
        `/surveys/${this.surveyId}/responses/list${this.outboundId ? `?outboundId=${this.outboundId}` : ''}`,
        {
          limit: null,
          order: ['createdAt'],
          descending: false,
        },
      ),
    ]);

    this.survey = surveyResponse.survey;
    this.totalResponses = surveyResponse.totalResponses;
    this.surveyScore = surveyResponse.score;
    this.surveyScoresByAssignee = surveyResponse.byAssignee;
    this.surveyResponses = surveyResponsesResponse.data;

    this.isLoading = false;
  }

  getPercentageScore(value: number) {
    return this.survey.type === 'nps' ? value / 10 : value / 5;
  }
}
